<template>
  <div class="container">
    <hr />

    <section class="top-section">
      <aside class="sidebar">
        <h2>Top Articles</h2>
        <ul>
          <li>
            <a href="product/hiking-boots">
              <p>Top 5 Hiking Boots for Extreme Weather Conditions</p>
            </a>
          </li>
          <li>
            <a href="product/energy-efficient-appliances">
              <p>The Best Energy Efficient Appliances for Your Home in 2024</p>
            </a>
          </li>
          <li>
            <a href="product/wireless-earbuds">
              <p>5 Best Wireless Earbuds for Music Lovers in 2024</p>
            </a>
          </li>
          <li>
            <a href="product/smart-home-devices">
              <p>Top 5 Smart Home Devices for a Connected Lifestyle in 2024</p>
            </a>
          </li>
          <li>
            <a href="product/workout-equipment">
              <p>5 Best Home Workout Equipment for Fitness Enthusiasts</p>
            </a>
          </li>
        </ul>
      </aside>

      <article class="content">
        <a href="product/portable-stove">
          <h2>Portable Camping Stoves for Backpackers</h2>
          <p class="author">by Jason From PineappleJar</p>
          <img
            src="@/assets/images/camp.png"
            alt="Home workout equipment"
            class="main-image"
          />
          <p>
            Lightweight, Efficient, and Adventure-Ready: The Top Camping Stoves
            for Your 2024 Outdoor Escapades
          </p>
        </a>
      </article>

      <aside class="deals">
        <h2>Hot Deals</h2>
        <ul>
          <li>
            <a href="product/smart-home-security-systems">
              <p>Top 5 Smart Home Security Systems of 2024</p>
            </a>
          </li>
          <li>
            <a href="product/workout-equipment">
              <p>5 Best Home Workout Equipment for Fitness Enthusiasts</p>
            </a>
          </li>
          <li>
            <a href="product/interactive-pet-toys">
              <p>
                Best 5 Interactive Pet Toys to Keep Your Furry Friends
                Entertained
              </p>
            </a>
          </li>
          <li>
            <a href="product/supplement">
              <p>Top 10 Nutritional Supplements for Boosting Your Immunity</p>
            </a>
          </li>
        </ul>
      </aside>
    </section>
    <hr />

    <section class="news-list">
      <h2>Trending Now</h2>
      <div class="news-grid">
        <div
          class="news-item"
          v-for="(article, index) in trending"
          :key="index"
        >
          <img :src="getImagePath(article.image)" :alt="article.title" />
          <div class="news-details">
            <a :href="article.url">{{ article.title }}</a>
            <p>{{ article.description }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HomePage",
  data() {
    return {
      trending: [],
      home: [],
    };
  },
  methods: {
    getImagePath(imagePath) {
      try {
        console.log(imagePath);
        return require(`@/assets/images/${imagePath}`);
      } catch (error) {
        console.error("Error loading image:", imagePath, error);
        return ""; // Fallback in case of error
      }
    },

    async fetchTrendingArticles() {
      try {
        const baseURL =
          process.env.NODE_ENV === "production"
            ? "https://pineapplejar-backend-0b5357b98917.herokuapp.com/api"
            : "http://localhost:8000/api";
        const response = await axios.get(`${baseURL}/trending`);

        this.trending = response.data;
      } catch (error) {
        console.error("Error fetching tech articles:", error);
      }
    },
  },
  mounted() {
    this.fetchTrendingArticles();
  },
};
</script>

<style scoped>
.custom-hr {
  border: 0;
  height: 3px;
  background: #0073e6;
  margin: 20px 0;
}

.container {
  font-family: Arial, sans-serif;
  max-width: 70%;
  margin: auto;
  padding: 0 rem 0rem;
}

/* Layout for the top section */
.top-section {
  display: flex;
  flex-wrap: wrap;
  gap: 3%;
  margin-bottom: 1rem;
}

/* Content Section Styling */
.content {
  flex: 1 1 50%;
  padding: 1.2rem;
  border-radius: 8px;
}

.content h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
}

.content .author {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 0.5rem;
}

.content p {
  font-size: 1rem;
  line-height: 1.6;
  color: #444;
  margin-bottom: 1.5rem;
}

.content .main-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
  margin-top: 1rem;
}

/* Sidebar and Deals Styling */
.sidebar,
.deals {
  flex: 1 1 20%;
  padding: 1rem;
  border-radius: 8px;
}

.sidebar h2,
.deals h2 {
  font-size: 1.8rem;
  color: #333;
  text-align: left;
  margin-bottom: 1.5rem;
}

.sidebar ul,
.deals ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li,
.deals ul li {
  margin-bottom: 1rem;
  border-bottom: 1px solid #ddd;
  padding: 0.5rem 0;
}

/* Link Styling */
.content a,
.sidebar ul li a,
.deals ul li a,
.news-details a {
  text-decoration: none; /* Remove underline */
  font-size: 1rem; /* Standard font size for links */
  color: #333; /* Set custom color (e.g., dark gray) */
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

.sidebar ul li a:hover,
.deals ul li a:hover,
.news-details a:hover {
  color: #005bb5; /* Optional: Change color on hover for better interactivity */
}

/* News List Styling */
.news-list {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.news-list h2 {
  margin-top: 1.8rem;
  margin-bottom: 1rem;
  font-size: 1.8rem;
}

.news-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Ensures exactly 3 items per row */
  gap: 1.5rem;
}

.news-item {
  text-align: center;
  overflow: hidden;
  border-bottom: 1px solid #ddd; /* Add separator */
  padding-bottom: 1rem; /* Space between image and separator */
  margin-bottom: 1rem; /* Space below separator */
}

.news-item img {
  padding: 1rem;
  max-width: 100%; /* Set a maximum width for the image */
  height: auto; /* Maintain the aspect ratio */
  margin: 0 auto; /* Center the image horizontally */
  display: inline-block; /* Ensure proper centering */
}

.news-details {
  padding: 1rem;
}

.news-details a {
  font-size: 1.15rem;
  font-weight: bold;
  color: #333;
}

.news-details p {
  font-size: 1rem;
  color: #555;
  margin-top: 0.5rem;
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 95%;
    padding: 0 rem;
  }

  .top-section {
    flex-direction: column;
  }

  .sidebar,
  .deals,
  .content {
    flex: 1 1 100%;
  }

  .content h2 {
    font-size: 1.4rem;
  }

  .sidebar h2,
  .deals h2,
  .news-list h2 {
    font-size: 1.4rem;
  }

  .news-grid {
    grid-template-columns: 1fr;
  }

  .news-item {
    text-align: left;
  }

  .news-item img {
    max-width: 100%;
    height: auto;
  }

  .news-details a {
    font-size: 1rem;
  }

  .news-details p {
    font-size: 0.9rem;
  }
}

/* Additional adjustments for very small screens */
@media screen and (max-width: 480px) {
  .content,
  .deals,
  .sidebar {
    padding: 1rem;
    margin: 0rem;
  }
  .content h2 {
    font-size: 1.2rem;
    padding: 0rem;
  }
  .sidebar h2,
  .deals h2,
  .news-list h2 {
    font-size: 1.2rem;
    padding: 0rem;
  }

  .sidebar ul li a,
  .deals ul li a,
  .news-details a {
    font-size: 0.9rem;
  }

  .news-details p {
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
}
</style>
