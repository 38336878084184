<template>
  <div class="container">
    <h1>Product Management</h1>

    <!-- Product Table -->
    <table class="product-table">
      <thead>
        <tr>
          <th>Thumbnail</th>
          <th>Name</th>
          <th>Price Range</th>
          <th>Rating</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in products" :key="product.id">
          <td>
            <img
              v-if="product.image"
              :src="product.image"
              alt="Product Thumbnail"
              class="thumbnail"
            />
            <span v-else>No Image</span>
          </td>

          <td>{{ product.name }}</td>
          <td>${{ product.min_price }} - ${{ product.max_price }}</td>
          <td>{{ product.rating }}</td>
          <td>
            <button class="edit-btn" @click="editProduct(product)">Edit</button>
            <button class="delete-btn" @click="deleteProduct(product.id)">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Product Form -->
    <div class="form-container">
      <h2>{{ editingProduct ? "Edit Product" : "Add Product" }}</h2>
      <form @submit.prevent="saveProduct" class="product-form">
        <div class="form-row">
          <div class="form-group">
            <label for="min_price">Min Price:</label>
            <input
              type="text"
              id="min_price"
              v-model="form.min_price"
              required
            />
          </div>
          <div class="form-group">
            <label for="max_price">Max Price:</label>
            <input
              type="text"
              id="max_price"
              v-model="form.max_price"
              required
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label for="discount">Discount (%):</label>
            <input
              type="number"
              id="discount"
              v-model="form.discount"
              required
            />
          </div>
          <div class="form-group">
            <label for="rating">Rating:</label>
            <input
              type="number"
              step="0.1"
              id="rating"
              v-model="form.rating"
              required
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-group">
            <label for="review_num">Review Count:</label>
            <input
              type="number"
              id="review_num"
              v-model="form.review_num"
              required
            />
          </div>
        </div>

        <div class="form-group">
          <label for="pros">Pros (comma-separated):</label>
          <input type="text" id="pros" v-model="form.pros" />
        </div>

        <div class="form-group">
          <label for="cons">Cons (comma-separated):</label>
          <input type="text" id="cons" v-model="form.cons" />
        </div>

        <div class="form-group">
          <label for="content">Content:</label>
          <textarea
            id="content"
            v-model="form.content"
            rows="1"
            class="auto-resize"
            @input="adjustTextareaHeight($event)"
            required
          ></textarea>
        </div>

        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" id="name" v-model="form.name" required />
        </div>

        <div class="form-group">
          <label for="description">Description:</label>
          <textarea
            id="description"
            v-model="form.description"
            class="auto-resize"
            @input="adjustTextareaHeight($event)"
            required
          ></textarea>
        </div>

        <div class="form-group">
          <label for="content">Content:</label>
          <textarea
            id="content"
            v-model="form.content"
            class="auto-resize"
            @input="adjustTextareaHeight($event)"
            required
          ></textarea>
        </div>

        <button type="submit" class="submit-btn">
          {{ editingProduct ? "Update Product" : "Add Product" }}
        </button>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      products: [],
      editingProduct: null,
      form: {
        name: "",
        description: "",
        image: "",
        content: "",
        min_price: "",
        max_price: "",
        discount: 0,
        rating: 0,
        review_num: 0,
        pros: "",
        cons: "",
      },
    };
  },
  methods: {
    async fetchProducts() {
      try {
        const response = await axios.get(
          "http://localhost:8000/api/temu/products"
        );
        this.products = response.data;
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },

    async deleteProduct(productId) {
      try {
        // Make DELETE request to the API
        await axios.delete(
          `http://localhost:8000/api/temu/products/${productId}`
        );
        this.fetchProducts();

        // Notify the user
      } catch (error) {
        console.error("Error deleting product:", error);
        alert(
          "An error occurred while deleting the product. Please try again."
        );
      }
    },

    editProduct(product) {
      this.editingProduct = product;
      this.form = {
        ...product,
        pros: product.pros.join(","),
        cons: product.cons.join(","),
      };
      this.$nextTick(() => {
        // Adjust textareas after data is loaded
        this.adjustAllTextareas();
      });
    },
    async saveProduct() {
      try {
        const payload = {
          ...this.form,
          pros: this.form.pros.split(",").map((p) => p.trim()),
          cons: this.form.cons.split(",").map((c) => c.trim()),
        };
        if (this.editingProduct) {
          await axios.put(
            `http://localhost:8000/api/temu/products/${this.editingProduct.id}`,
            payload
          );
        } else {
          await axios.post("http://localhost:8000/api/temu/products", payload);
        }
        this.fetchProducts();
        this.resetForm();
      } catch (error) {
        console.error("Error saving product:", error);
      }
    },
    resetForm() {
      this.editingProduct = null;
      this.form = {
        name: "",
        description: "",
        image: "",
        content: "",
        min_price: "",
        max_price: "",
        discount: 0,
        rating: 0,
        review_num: 0,
        pros: "",
        cons: "",
      };
      this.$nextTick(() => {
        this.adjustAllTextareas();
      });
    },
    adjustTextareaHeight(event) {
      const textarea = event.target;
      textarea.style.height = "auto"; // Reset the height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on content
    },
    adjustAllTextareas() {
      const textareas = document.querySelectorAll(".auto-resize");
      textareas.forEach((textarea) => {
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      });
    },
  },
  mounted() {
    this.fetchProducts();
    this.$nextTick(() => {
      this.adjustAllTextareas();
    });
  },
};
</script>

<style scoped>
.container {
  font-family: Arial, sans-serif;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.product-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.product-table th,
.product-table td {
  padding: 12px 16px;
  text-align: left;
}

.product-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.product-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.product-table tr:hover {
  background-color: #f1f1f1;
}

.edit-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}

.edit-btn:hover {
  background-color: #0056b3;
}

.delete-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: #b02a37;
}

.form-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.product-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 6px;
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-row .form-group {
  flex: 1;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

input:focus,
textarea:focus {
  border-color: #007bff;
  outline: none;
}

.submit-btn {
  width: 100%;
  background-color: #28a745;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.submit-btn:hover {
  background-color: #218838;
}
textarea.auto-resize {
  width: 100%;
  min-height: 50px; /* Minimum height */
  max-height: 800px; /* Maximum height */
  overflow-y: hidden; /* Hide scrollbar when not needed */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1.1rem;
  line-height: 1.5;
  resize: none; /* Prevent manual resizing */
}

.thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.product-table td {
  vertical-align: middle; /* Align content to the middle */
}
</style>
