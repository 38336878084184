<template>
  <div class="best-mattresses-container">
    <!-- Headline Section -->
    <section>
      <HeadlineSection :data="allData" />
    </section>

    <!-- What to Consider Section -->
    <section class="content-section">
      <h1 class="section-title">Part 1: What to consider</h1>
      <p v-if="allData && allData.intro">{{ allData.intro }}</p>
      <p v-else></p>
      <WhatToConsider
        v-if="whatToConsiderData"
        :title="'Factors to Consider'"
        :items="whatToConsiderData"
      />
      <p v-else>Loading factors to consider...</p>
    </section>

    <!-- Side By Side Comparison Section -->
    <section class="content-section">
      <h1 class="section-title">Part 2: Side By Side Comparison</h1>
      <SideBySideComparison
        v-if="sideBySideComparisonData"
        :products="sideBySideComparisonData"
      />
      <p v-else>Loading comparison data...</p>
    </section>

    <!-- Product Card Section -->
    <section class="content-section">
      <h1 class="section-title">Part 3: Highly recommended items</h1>
      <ProductCard v-if="productCardData" :products="productCardData" />
      <p v-else>Loading product cards...</p>
    </section>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import ProductCard from "@/components/product/ProductItem.vue";
import SideBySideComparison from "@/components/product/CompareItem.vue";
import HeadlineSection from "@/components/product/HeadlineSection.vue";
import WhatToConsider from "@/components/product/WhatToConsider.vue";

export default {
  name: "ProductPage",
  components: {
    ProductCard,
    SideBySideComparison,
    HeadlineSection,
    WhatToConsider,
  },
  setup() {
    const route = useRoute();
    const productName = ref(route.params.productName || "Default Product");
    const whatToConsiderData = ref(null);
    const sideBySideComparisonData = ref(null);
    const productCardData = ref(null);
    const allData = ref(null);

    const fetchData = async () => {
      const baseURL =
        process.env.NODE_ENV === "production"
          ? "https://pineapplejar-backend-0b5357b98917.herokuapp.com/api"
          : "http://localhost:8000/api";

      try {
        const response = await fetch(
          `${baseURL}/product?item=${productName.value}`
        );
        const data = await response.json();
        if (data) {
          whatToConsiderData.value = data.features_list || [];
          sideBySideComparisonData.value = Array.isArray(data.products)
            ? data.products
            : []; // Ensure it's an array
          productCardData.value = Array.isArray(data.products)
            ? data.products
            : []; // Ensure it's an array
          allData.value = data;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Set defaults to avoid errors in child components
        whatToConsiderData.value = [];
        sideBySideComparisonData.value = [];
        productCardData.value = [];
        allData.value = {};
      }
    };

    onMounted(fetchData);

    return {
      productName,
      whatToConsiderData,
      sideBySideComparisonData,
      productCardData,
      allData,
    };
  },
};
</script>

<style scoped>
/* Global Container */
.best-mattresses-container {
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.5;
  max-width: 70%;
  margin: auto;
  padding: 1rem;
}

/* Content Sections */
.content-section {
  margin-bottom: 2rem;
}

.content-section p {
  margin-bottom: 2rem;
  color: black;
  text-align: left;
}
.section-title {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

/* Loading Message */
p {
  font-size: 1rem;
  color: #999;
  text-align: center;
}

/* Responsive Design */
@media (max-width: 768px) {
  .section-title {
    font-size: 1.5rem;
  }
  .best-mattresses-container {
    padding: 0.5rem;
  }
}
</style>
