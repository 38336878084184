<template>
  <div class="container" v-if="content">
    <!-- Background Information -->
    <div class="background-info">
      <header class="info-header">
        <h1 class="article-title">
          {{ content.article_title }}
        </h1>
        <p class="meta-info">
          Written by <span class="author">{{ content.author_name }}</span> |
          Edited by
          <span class="editor">{{ content.editor_name }}</span>
        </p>
      </header>
      <div class="intro-content">
        <p v-for="(paragraph, index) in content.intro_paragraphs" :key="index">
          {{ paragraph }}
        </p>
      </div>
      <figure class="info-image">
        <img
          :src="getImagePath(content.main_image[0])"
          :alt="content.main_image_alt"
          class="main-image"
        />
        <figcaption class="info-caption">
          {{ content.main_image_caption }}
          <span class="credit">{{ content.main_image_credit }}</span>
        </figcaption>
      </figure>

      <div class="additional-content">
        <p v-html="renderContent(content.additional_content)"></p>
      </div>
    </div>

    <!-- Product List -->
    <div class="product-list">
      <h2 class="product-list-title">Products Trending</h2>
      <div class="product-cards">
        <ProductCard
          v-for="product in content.products"
          :key="product.id"
          v-bind="product"
        />
      </div>
    </div>
  </div>
  <div v-else class="loading">Loading content...</div>
</template>

<script>
import axios from "axios";
import ProductCard from "@/components/temu/product-card.vue";

export default {
  components: { ProductCard },
  data() {
    return {
      content: null, // Placeholder for fetched content
    };
  },
  methods: {
    // Fetch content based on the route parameter
    async fetchContent() {
      try {
        const articleID = this.$route.params.articleID; // Get the route parameter
        const baseURL =
          process.env.NODE_ENV === "production"
            ? "https://pineapplejar-backend-0b5357b98917.herokuapp.com/api"
            : "http://localhost:8000/api";

        const response = await axios.get(
          `${baseURL}/temu/articles/${articleID}`
        );
        this.content = response.data;
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    },
    // Get the image path, handling both URLs and local files
    getImagePath(imagePath) {
      try {
        // Check if the path is an absolute URL
        if (/^(https?:\/\/)/.test(imagePath)) {
          return imagePath; // Return full URL if provided
        }

        // Handle absolute paths by extracting the filename and converting to require format
        if (
          imagePath.startsWith(
            "/Users/hao/work/pineapplejar/pineapplejar-frontend/src/assets/images/"
          )
        ) {
          const relativePath = imagePath.replace(
            "/Users/hao/work/pineapplejar/pineapplejar-frontend/src/assets/images/",
            ""
          );
          return require(`@/assets/images/${relativePath}`); // Convert to require format
        }

        // Handle relative paths (e.g., for local assets)
        return require(`@/assets/images/${imagePath}`);
      } catch (error) {
        console.error("Error loading image:", imagePath, error);
        return require("@/assets/images/bathmat.jpg"); // Fallback image
      }
    },

    // Render content, replacing newlines with <br>
    renderContent(content) {
      if (!content) return "";
      return content.replace(/\n/g, "<br>"); // Replace \n with <br>
    },
  },
  created() {
    this.fetchContent(); // Fetch content when the component is created
  },
};
</script>

<style scoped>
.container {
  font-family: "Roboto", sans-serif;
  color: #333;
  max-width: 65%; /* Adjust for smaller screens */
  margin: 0 auto;
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.loading {
  text-align: center;
  font-size: 1.2rem;
  color: #666;
}

.background-info {
  margin-bottom: 2.5rem;
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.info-header {
  margin-bottom: 1.5rem;
  text-align: center;
}

.article-title {
  font-size: 2rem; /* Adjust font size for smaller screens */
  font-weight: 700;
  color: #222;
  margin-bottom: 0.5rem;
}

.meta-info {
  font-size: 1rem;
  color: #666;
}

.meta-info .author,
.meta-info .editor {
  font-weight: bold;
  color: #007bff;
}

.intro-content {
  text-align: justify;
  line-height: 1.8;
  font-size: 1rem; /* Adjust font size for smaller screens */
  margin-bottom: 1.5rem;
}

.info-image {
  text-align: center;
  margin-bottom: 1.5rem;
}

.info-image img.main-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
}

.info-image img.main-image:hover {
  transform: scale(1.05);
}

.info-caption {
  font-size: 0.8rem; /* Adjust font size for smaller screens */
  color: #777;
  margin-top: 0.5rem;
}

.info-caption .credit {
  font-style: italic;
  color: #555;
}

.additional-content {
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  font-size: 1rem; /* Adjust font size for smaller screens */
  line-height: 1.8;
}

.product-list {
  margin-top: 2rem;
}

.product-list-title {
  font-size: 1.5rem; /* Adjust font size for smaller screens */
  font-weight: 700;
  color: #222;
  text-align: center;
  margin-bottom: 1.5rem;
}

.product-cards {
  display: flex;
  flex-direction: column; /* Stack products vertically */
  gap: 1rem; /* Adjust spacing for smaller screens */
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .container {
    max-width: 100%;
    padding: 1rem;
  }

  .article-title {
    font-size: 1.8rem;
  }

  .intro-content {
    font-size: 0.9rem;
  }

  .info-caption {
    font-size: 0.75rem;
  }

  .additional-content {
    font-size: 0.9rem;
  }

  .product-list-title {
    font-size: 1.4rem;
  }

  .product-cards {
    gap: 1rem;
  }
}

/* Further Adjustments for Very Small Screens */
@media (max-width: 480px) {
  .article-title {
    font-size: 1.5rem;
  }

  .intro-content {
    font-size: 0.8rem;
    line-height: 1.6;
  }

  .info-caption {
    font-size: 0.7rem;
  }

  .product-list-title {
    font-size: 1.2rem;
  }

  .product-cards {
    gap: 0.8rem;
  }
}
</style>
