<template>
  <nav class="navbar">
    <div class="nav-container">
      <!-- Hamburger Icon -->
      <div class="hamburger" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
      </div>

      <!-- Logo -->
      <div class="logo">
        <a href="/" class="logo-link">
          <img
            src="@/assets/images/pineapple.jpg"
            alt="Pineapple Jar Logo"
            class="logo-img"
          />
          <span class="logo-text">Pineapple Jar</span>
        </a>
      </div>

      <!-- Main Navigation Links -->
      <ul class="nav-main" :class="{ open: menuOpen }">
        <li v-for="(item, index) in navItems" :key="item.id" class="nav-item">
          <!-- Check if the menu item has submenus -->
          <a
            :href="item.url"
            :class="{ active: activeIndex === index }"
            @click="item.submenus ? toggleSubMenu(index) : null"
          >
            {{ item.title }}
          </a>
          <!-- Submenu -->
          <div
            v-if="item.submenus && item.submenus.length"
            class="submenu"
            :class="{ active: activeIndex === index }"
          >
            <ul>
              <li v-for="link in item.submenus" :key="link.id">
                <a :href="link.url">{{ link.title }}</a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      activeIndex: null, // Tracks the currently active menu for submenu functionality
      navItems: [], // Holds the menu and submenu data fetched from the API
      menuOpen: false, // Tracks whether the mobile menu is open
    };
  },
  methods: {
    async fetchMenu() {
      try {
        const baseURL =
          process.env.NODE_ENV === "production"
            ? "https://pineapplejar-backend-0b5357b98917.herokuapp.com/api"
            : "http://localhost:8000/api";

        const response = await axios.get(`${baseURL}/menu`);
        this.navItems = response.data;
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
      document.body.style.overflow = this.menuOpen ? "hidden" : ""; // Prevent scrolling when menu is open
    },
    toggleSubMenu(index) {
      this.activeIndex = this.activeIndex === index ? null : index; // Toggle submenu for mobile
    },
  },
  mounted() {
    this.fetchMenu();
  },
};
</script>

<style>
/* Navbar Styles */
.navbar {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 1rem 0rem;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 70%; /* Default for larger screens */
  margin: 0 auto;
  padding: 0rem 0rem; /* Default padding */
}

@media (max-width: 1024px) {
  .nav-container {
    max-width: 80%; /* Adjust container width for medium screens */
    padding: 0 20px; /* Reduce padding */
  }
}

@media (max-width: 768px) {
  .nav-container {
    max-width: 100%; /* Full width for smaller screens */
    padding: 0 10px; /* Further reduce padding for small screens */
  }
}

@media (max-width: 480px) {
  .nav-container {
    padding: 0 5px; /* Minimal padding for very small screens */
  }
}

.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
  transition: 0.3s;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-link {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.logo-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.logo-text {
  margin-left: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  white-space: nowrap; /* Prevent text wrapping for submenu items */
}

.nav-main {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-main.open {
  display: block;
}

.nav-item {
  margin: 0 15px;
  position: relative;
  white-space: nowrap; /* Prevent text wrapping */
}

.nav-item a {
  text-decoration: none;
  font-size: 1rem;
  color: #333;
  padding: 0px 0px;
  white-space: nowrap; /* Prevent text wrapping */
}

.nav-item a.active {
  color: #000;
  font-weight: bold;
}

.submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 10;
  padding: 10px 0;
}

.submenu.active {
  display: block;
}

.submenu ul {
  list-style: none;
  margin: 0;
  padding: 10px 0;
}

.submenu li {
  padding: 5px 20px;
  white-space: nowrap; /* Prevent text wrapping for submenu items */
}

.submenu a {
  text-decoration: none;
  font-size: 0.9rem;
  color: #333;
  white-space: nowrap; /* Prevent text wrapping for submenu links */
}

.submenu a:hover {
  color: #000;
}

.nav-item:hover .submenu {
  display: block; /* Show submenu when parent is hovered */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .hamburger {
    display: block; /* Show hamburger icon for mobile */
  }

  .nav-main {
    display: none; /* Hide menu by default */
    flex-direction: column; /* Stack menu items */
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    width: 100%;
    border-top: 1px solid #ddd;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .nav-main.open {
    display: flex; /* Show menu when toggled */
  }

  .submenu {
    display: none; /* Hide submenu by default */
    position: static; /* No absolute positioning */
    width: 100%; /* Ensure full width */
    background-color: #fff; /* Match menu background */
    padding: 10px;
  }

  .submenu.active {
    display: block; /* Show submenu when active */
    flex-direction: column; /* Stack submenu items vertically */
  }

  .nav-item {
    width: 100%; /* Ensure parent nav items take full width */
  }
  .logo {
    display: flex;
    margin-right: 10%;
  }
  .logo-text {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    white-space: nowrap; /* Prevent text wrapping for submenu items */
  }
}
</style>
