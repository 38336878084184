<template>
  <h1>About Page</h1>
</template>

<script>
export default {
  props: {
    //name: 'About'
  },
};
</script>

<style></style>
