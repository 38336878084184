<template>
  <div>
    <NavBar />
    <router-view />
    <FooterSection />
  </div>
</template>

<script>
import NavBar from "./modules/navbar-section.vue";
import FooterSection from "./modules/footer-section.vue";

export default {
  name: "App",
  components: {
    NavBar,
    FooterSection,
  },
};
</script>

<style lang="scss"></style>
