<template>
  <div class="product-card">
    <div class="product-header">
      <span class="product-category">{{ category }}</span>
      <h2 class="product-title">{{ name }}</h2>
    </div>
    <div class="product-body">
      <img :src="getImagePath(image)" :alt="name" class="product-image" />
      <div class="product-info">
        <p class="product-description">{{ description }}</p>
        <p class="product-price">
          Price: <strong>${{ min_price }}</strong> -
          <strong>${{ max_price }}</strong>
        </p>

        <p class="product-rating">
          Rating: <strong>{{ rating }}</strong> ({{ review_num }} reviews)
        </p>
        <a :href="url" target="_blank" class="product-link"
          >View Product on Temu</a
        >
      </div>
    </div>
    <div class="product-pros-cons">
      <div class="pros">
        <h4>What we like</h4>
        <ul>
          <li v-for="pro in pros" :key="pro">✔ {{ pro }}</li>
        </ul>
      </div>
      <div class="cons">
        <h4>What we don't like</h4>
        <ul>
          <li v-for="con in cons" :key="con">✘ {{ con }}</li>
        </ul>
      </div>
    </div>
    <div class="product-content">
      <h2>Why we like it:</h2>
      <p v-html="renderContent(content)"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    min_price: {
      type: String,
      required: true,
    },
    max_price: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    discount: {
      type: Number,
      required: true,
    },
    review_num: {
      type: Number,
      required: true,
    },
    rating: {
      type: Number,
      required: true,
    },
    pros: {
      type: Array,
      required: true,
    },
    cons: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getImagePath(imagePath) {
      try {
        if (/^(https?:\/\/)/.test(imagePath)) {
          return imagePath;
        }
        return require(`@/assets/images/${imagePath}`);
      } catch (error) {
        console.error("Error loading image:", imagePath, error);
        return require("@/assets/images/bathmat.jpg");
      }
    },
    renderContent(content) {
      return content.replace(/\n/g, "<br>"); // Replace \n with <br>
    },
  },
};
</script>

<style scoped>
.product-card {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 24px;
  background-color: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
  max-width: 800px;
  margin: 24px auto;
  font-family: "Roboto", sans-serif;
}

.product-header {
  margin-bottom: 16px;
}

.product-category {
  background-color: #ffc107;
  color: #000;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.85rem;
  font-weight: bold;
  text-transform: uppercase;
  display: inline-block;
}

.product-title {
  margin-top: 8px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
}

.product-body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.product-image {
  width: 100%;
  max-width: 250px;
  height: auto;
  border-radius: 8px;
  margin: 0 auto;
  display: block;
  transition: transform 0.3s ease;
}

.product-image:hover {
  transform: scale(1.05);
}

.product-info {
  text-align: left;
}

.product-description {
  font-size: 1rem;
  margin-bottom: 8px;
  line-height: 1.6;
}

.product-price,
.product-discount,
.product-rating {
  font-size: 0.95rem;
  margin: 6px 0;
}

.product-link {
  display: inline-block;
  margin-top: 8px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.product-link:hover {
  color: #0056b3;
}

.product-pros-cons {
  display: flex;
  justify-content: space-between;
  gap: 24px; /* Add space between the two sections */
  margin-top: 24px;
}

.pros,
.cons {
  flex: 1; /* Ensure both sections take equal width */
  padding: 16px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.pros h4,
.cons h4 {
  margin-bottom: 8px;
  font-size: 1.1rem;
  color: #333;
}

.pros ul,
.cons ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.pros li {
  color: #28a745;
  margin-bottom: 6px;
}

.cons li {
  color: #dc3545;
  margin-bottom: 6px;
}

.product-content {
  margin-top: 24px;
  background-color: #f8f8f8;
  padding: 16px;
  border-radius: 8px;
}

.product-content h2 {
  margin-bottom: 12px;
  font-size: 1.5rem;
  font-weight: bold;
}

.product-content p {
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}
</style>
