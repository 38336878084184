<template>
  <div class="footer">
    <div class="attribution">
      <div class="challenge">Contact us by info@pineapplejar.com</div>
      <div class="coded">
        We’re here to slice through the chaos of online reviews and serve up the
        sweetest, most comprehensive product rundowns—all in one place.
        PineappleJar Inc.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterSection",
};
</script>

<style lang="scss" scoped>
.footer {
  height: 10rem;
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
}

.attribution {
  text-align: center;
}

.attribution .challenge,
.attribution .coded {
  margin: 1rem;
}

.attribution .challenge a {
  text-decoration: none;
  color: aqua;
}

.attribution .coded a {
  text-decoration: none;
  color: yellow;
}
</style>
