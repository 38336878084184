<template>
  <div class="container">
    <h1>Article Management</h1>

    <!-- Article Table -->
    <table class="article-table">
      <thead>
        <tr>
          <th>Title</th>
          <th>Author</th>
          <th>Editor</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="article in articles" :key="article.id">
          <td>{{ article.article_title }}</td>
          <td>{{ article.author_name }}</td>
          <td>{{ article.editor_name }}</td>
          <td>
            <button class="edit-btn" @click="editArticle(article)">Edit</button>
            <button class="delete-btn" @click="deleteArticle(article.id)">
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Article Form -->
    <div class="form-container">
      <h2>{{ editingArticle ? "Edit Article" : "Add Article" }}</h2>
      <form @submit.prevent="saveArticle" class="article-form">
        <div class="form-group">
          <label for="article_title">Title</label>
          <input
            type="text"
            id="article_title"
            v-model="form.article_title"
            required
          />
        </div>
        <div class="form-row">
          <div class="form-group">
            <label for="author_name">Author</label>
            <input
              type="text"
              id="author_name"
              v-model="form.author_name"
              required
            />
          </div>
          <div class="form-group">
            <label for="editor_name">Editor</label>
            <input
              type="text"
              id="editor_name"
              v-model="form.editor_name"
              required
            />
          </div>
        </div>
        <div class="form-group">
          <label for="intro_paragraphs">Introduction Paragraphs</label>
          <input
            type="text"
            id="intro_paragraphs"
            v-model="form.intro_paragraphs"
          />
        </div>
        <div class="form-group">
          <label for="main_image">Main Image URLs</label>
          <input type="text" id="main_image" v-model="form.main_image" />
        </div>
        <div class="form-group">
          <label for="additional_content">Additional Content</label>
          <textarea
            id="additional_content"
            v-model="form.additional_content"
            rows="4"
          ></textarea>
        </div>

        <div class="form-group">
          <label for="linked-products">Linked Products</label>
          <div class="linked-products">
            <ul>
              <li v-for="(productId, index) in form.products" :key="index">
                <span>
                  {{ getProductName(productId) }} (ID: {{ productId }})
                </span>
                <button @click="removeProduct(productId)" class="remove-btn">
                  Remove
                </button>
              </li>
            </ul>
            <select
              id="available-products"
              v-model="selectedProduct"
              class="multi-select"
            >
              <option value="" disabled>Select a product to add</option>
              <option
                v-for="product in availableProducts"
                :key="product.id"
                :value="product.id"
                :disabled="form.products.includes(product.id)"
              >
                {{ product.name }} (ID: {{ product.id }})
              </option>
            </select>
            <button @click="addProduct" class="add-btn">Add Product</button>
          </div>
        </div>

        <button type="submit" class="submit-btn">Save</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      articles: [], // List of articles
      availableProducts: [], // List of all available products
      editingArticle: null, // Current article being edited
      selectedProduct: "", // Product ID selected from the dropdown
      form: {
        article_title: "",
        author_name: "",
        editor_name: "",
        intro_paragraphs: "",
        main_image: "",
        additional_content: "",
        product_list_title: "",
        products: [], // Product IDs associated with the article
      },
    };
  },
  methods: {
    // Fetch all articles
    async fetchArticles() {
      try {
        const response = await axios.get(
          "http://localhost:8000/api/temu/articles/"
        );
        this.articles = response.data;
      } catch (error) {
        console.error("Error fetching articles:", error);
      }
    },
    // Fetch available products from the API
    async fetchProducts() {
      try {
        const response = await axios.get(
          "http://localhost:8000/api/temu/products/"
        );
        this.availableProducts = response.data;
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    // Get product name by ID
    getProductName(productId) {
      const product = this.availableProducts.find((p) => p.id === productId);
      return product ? product.name : "Unknown Product";
    },
    // Add product to the linked products list
    addProduct() {
      if (
        this.selectedProduct &&
        !this.form.products.includes(this.selectedProduct)
      ) {
        this.form.products.push(this.selectedProduct);
        this.selectedProduct = ""; // Reset the selected product
      }
    },
    // Remove product from the linked products list
    removeProduct(productId) {
      this.form.products = this.form.products.filter((id) => id !== productId);
    },
    // Edit an article
    editArticle(article) {
      this.editingArticle = article;
      this.form = {
        ...article,
        intro_paragraphs: article.intro_paragraphs.join(","),
        main_image: article.main_image.join(","),
        products: article.products.map((product) => product.id), // Extract product IDs
      };
    },
    // Save an article
    async saveArticle() {
      try {
        const payload = {
          ...this.form,
          intro_paragraphs: this.form.intro_paragraphs
            ? this.form.intro_paragraphs.split(",").map((p) => p.trim())
            : [],
          main_image: this.form.main_image
            ? this.form.main_image.split(",").map((img) => img.trim())
            : [],
          products: this.form.products.map((id) => ({ id })), // Map product IDs to objects with 'id' field
        };
        console.log(payload);
        if (this.editingArticle) {
          await axios.put(
            `http://localhost:8000/api/temu/articles/${this.editingArticle.id}`,
            payload
          );
        } else {
          await axios.post("http://localhost:8000/api/temu/articles/", payload);
        }

        this.fetchArticles();
        this.resetForm();
      } catch (error) {
        console.error("Error saving article:", error.response?.data || error);
      }
    },
    // Reset the form
    resetForm() {
      this.editingArticle = null;
      this.selectedProduct = "";
      this.form = {
        article_title: "",
        author_name: "",
        editor_name: "",
        intro_paragraphs: "",
        main_image: "",
        additional_content: "",
        product_list_title: "",
        products: [],
      };
    },
  },
  mounted() {
    this.fetchArticles();
    this.fetchProducts();
  },
};
</script>
<style scoped>
.container {
  font-family: "Roboto", Arial, sans-serif;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.article-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.article-table th,
.article-table td {
  padding: 12px 16px;
  text-align: left;
}

.article-table th {
  background-color: #f8f9fa;
  color: #333;
  font-weight: bold;
}

.article-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.article-table tr:hover {
  background-color: #f1f1f1;
}

.edit-btn,
.delete-btn {
  padding: 6px 12px;
  margin-right: 4px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-btn {
  background-color: #007bff;
  color: white;
}

.edit-btn:hover {
  background-color: #0056b3;
}

.delete-btn {
  background-color: #dc3545;
  color: white;
}

.delete-btn:hover {
  background-color: #b02a37;
}

.form-container {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  margin-bottom: 20px;
  color: #333;
}

.article-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 16px;
}

.form-row {
  display: flex;
  gap: 16px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 6px;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff;
  outline: none;
}

.submit-btn {
  padding: 12px 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.submit-btn:hover {
  background-color: #218838;
}
</style>
