<template>
  <div class="headline-section">
    <hr class="divider" />
    <h1 class="title">{{ data?.article_name || "Default Title" }}</h1>
    <p class="updated-date">
      Updated
      <time :datetime="formatDateTime(updatedDate)">
        {{ updatedDate }}
      </time>
    </p>
  </div>
</template>

<script>
export default {
  name: "HeadlineSection",
  props: {
    data: {
      type: Object,
      required: true, // Parent must provide data
    },
    updatedDate: {
      type: String,
      default: "October 29, 2024",
    },
  },
  methods: {
    formatDateTime(dateStr) {
      const date = new Date(dateStr);
      if (!isNaN(date)) {
        return date.toISOString().split("T")[0]; // e.g. "2024-10-29"
      }
      return ""; // Return empty if parsing fails
    },
  },
};
</script>

<style scoped>
.headline-section {
  font-family: Arial, sans-serif;
  color: #333;
  background: #f7f7f7;
  padding: 2rem;
  max-width: 85%;
  margin: 1rem auto;
  text-align: center;
}

.divider {
  border: none;
  border-top: 4px solid #000;
  margin: 1.5rem 0;
  width: 100%;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem 0;
}

.updated-date {
  font-size: 0.9rem;
  color: #666;
}
/* Responsive Design */
@media (max-width: 768px) {
  .headline-section {
    max-width: 85%;
  }
  .title {
    font-size: 1.5rem;
    padding: 0rem;
    margin: 0rem;
  }
  .best-mattresses-container {
    padding: 0rem;
  }
}

@media screen and (max-width: 480px) {
  .headline-section {
    max-width: 100%;
    padding: 1rem;
  }
  .title {
    font-size: 1.5rem;
    padding: 0rem;
  }
  .best-mattresses-container {
    padding: 0rem;
  }
}
</style>
