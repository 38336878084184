<template>
  <div class="not-found">
    <h1>404 - Page Not Found</h1>
    <p>Oops! The page you are looking for doesn't exist.</p>
    <router-link to="/">Go back to the homepage</router-link>
  </div>
</template>

<script>
export default {
  name: "NotFoundPage",
};
</script>

<style scoped>
.not-found {
  text-align: center;
  margin-top: 5rem;
  font-family: "Roboto", sans-serif;
}

.not-found h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #ff6b6b;
}

.not-found p {
  font-size: 1.2rem;
  color: #666;
}

.not-found a {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.not-found a:hover {
  background-color: #0056b3;
}
</style>
