<template>
  <section class="consider-section">
    <div class="items-grid">
      <div class="consider-item" v-for="(item, index) in items" :key="index">
        <div class="icon" aria-hidden="true">✔️</div>
        <div class="text-content">
          <h3 class="item-title">{{ item.criterion }}</h3>
          <p class="item-description">{{ item.reasoning }}</p>
          <p class="importance-score">
            <strong>Importance:</strong> {{ item.importance_score }}/5
          </p>
        </div>
      </div>
      <!-- Filler items to balance grid -->
      <div
        class="consider-item filler"
        v-for="index in fillerItems"
        :key="'filler-' + index"
        aria-hidden="true"
      ></div>
    </div>
  </section>
</template>

<script>
export default {
  name: "WhatToConsider",
  props: {
    title: {
      type: String,
      default: "What to consider",
    },
    items: {
      type: Array,
      required: true, // Parent must provide data
    },
  },
  computed: {
    fillerItems() {
      // Calculate number of filler items needed to complete the last row
      const columns = 3; // Number of columns in the grid
      const remainder = this.items.length % columns;
      return remainder === 0 ? 0 : columns - remainder;
    },
  },
};
</script>

<style scoped>
.consider-section {
  font-family: Arial, sans-serif;
  background: rgb(220, 223, 228); /* Light blue background */
  padding: 1.25rem;
  border-radius: 8px;
  max-width: 100%;
  margin: 1rem auto;
}

.items-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(3, 1fr); /* Ensure 3 items per row */
}

.consider-item {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  background: #fff; /* White background for contrast */
  padding: 1rem;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation */
}

.consider-item.filler {
  visibility: hidden; /* Hide filler items visually but keep them in the layout */
}

.icon {
  font-size: 1.5rem;
  color: #000;
  margin-top: 0.25rem;
}

.text-content {
  flex: 1;
}

.item-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0 0 0.5rem 0;
  color: #000;
}

.item-description {
  font-size: 0.95rem;
  color: #333;
  margin: 0 0 0.5rem 0;
}

.importance-score {
  font-size: 0.9rem;
  color: #555;
  margin: 0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .items-grid {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 items per row for medium screens */
  }
  .item-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0 0 0.5rem 0;
    color: #000;
  }
}

@media (max-width: 468px) {
  .items-grid {
    grid-template-columns: 1fr; /* 1 item per row for small screens */
  }
  .item-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0 0 0.5rem 0;
    color: #000;
  }
}
</style>
