<template>
  <div class="comparison-container">
    <div class="comparison-table-container">
      <table class="comparison-table">
        <thead>
          <tr>
            <th scope="col">Features</th>
            <th
              v-for="product in visibleProducts"
              :key="'header-' + product.model"
              class="product-header"
              scope="col"
            >
              {{ product.brand }} {{ product.model }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(feature, index) in featureHeaders"
            :key="'feature-' + index"
          >
            <th scope="row">{{ feature }}</th>
            <td
              v-for="product in visibleProducts"
              :key="'feature-' + product.model + '-' + feature"
            >
              {{ product.features[feature] || "N/A" }}
            </td>
          </tr>
          <tr>
            <th scope="row">Actions</th>
            <td
              v-for="product in visibleProducts"
              :key="'cta-' + product.model"
            >
              <a
                :href="product.url || '#'"
                target="_blank"
                rel="noopener noreferrer"
                class="cta-button"
              >
                CHECK PRICE
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBySideComparison",
  props: {
    products: {
      type: [Array, Object], // Allow both array and object formats
      required: true,
    },
  },
  computed: {
    // Extract unique features from product data
    featureHeaders() {
      if (!this.validProducts.length) return [];
      const features = new Set();
      this.validProducts.forEach((product) => {
        Object.keys(product.features || {}).forEach((key) => features.add(key));
      });
      return Array.from(features);
    },
    // Filter valid products and adjust for visible columns
    visibleProducts() {
      return this.validProducts.slice(0, this.visibleColumns);
    },
    // Ensure products is an array
    validProducts() {
      if (Array.isArray(this.products)) {
        return this.products;
      } else if (typeof this.products === "object") {
        return Object.values(this.products);
      }
      console.error("Invalid products format:", this.products);
      return [];
    },
  },
  data() {
    return {
      visibleColumns: 2, // Default number of visible columns
    };
  },
  methods: {
    adjustVisibleColumns() {
      const width = window.innerWidth;
      if (width < 600) {
        this.visibleColumns = 1; // Show only 1 column for small screens
      } else if (width < 900) {
        this.visibleColumns = 2; // Show 2 columns for medium screens
      } else {
        this.visibleColumns = this.validProducts.length; // Show all columns for large screens
      }
    },
  },
  mounted() {
    this.adjustVisibleColumns();
    window.addEventListener("resize", this.adjustVisibleColumns);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.adjustVisibleColumns);
  },
};
</script>

<style scoped>
.comparison-container {
  font-family: Arial, sans-serif;
  color: #0d0427;
  max-width: 1200px;
  margin: auto;
  padding: 1rem;
}

.comparison-table-container {
  overflow-x: auto; /* Enable horizontal scrolling for small screens */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
}

.comparison-table {
  width: 100%; /* Ensure table spans full container width */
  min-width: 600px; /* Set minimum width for horizontal scrolling */
  border-collapse: collapse;
  text-align: left;
  font-size: 0.9rem;
  background: #fff;
}

.comparison-table th,
.comparison-table td {
  border: 1px solid #ddd;
  padding: 0.75rem;
  vertical-align: top;
}

.product-header {
  text-align: center;
  background: #f8f8f8;
}

.cta-button {
  display: inline-block;
  background: #007bff;
  color: #fff;
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
}

.cta-button:hover {
  background: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .comparison-table-container {
    overflow-x: scroll; /* Allow scrolling for smaller screens */
  }

  .comparison-table th,
  .comparison-table td {
    font-size: 0.8rem; /* Adjust font size for better readability */
    padding: 0.5rem;
  }
}
</style>
