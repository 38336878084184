<template>
  <div class="mattress-card" v-for="(product, index) in products" :key="index">
    <header class="header-section">
      <div v-if="product.isBestValue" class="best-value-badge">Best Value</div>
      <div class="score-section">
        Product Details
        <span
          class="stars"
          :aria-label="`Rating: ${product.rating || 0} stars`"
        >
          {{
            "★".repeat(product.rating || 0) +
            "☆".repeat(5 - (product.rating || 0))
          }}
        </span>
      </div>
    </header>

    <div class="content-section">
      <div class="image-gallery">
        <img
          :src="getImagePath(product.image_url)"
          :alt="product.product_name || 'Product image'"
          class="main-image"
        />
      </div>

      <div class="product-info">
        <h1 class="product-name">
          {{ product.model || "Unnamed Product" }}
        </h1>
        <ul class="highlights">
          <li v-for="(value, key) in product.features" :key="key">
            <strong>{{ formatKey(key) }}:</strong> {{ value || "N/A" }}
          </li>
        </ul>
        <a
          :href="product.URL || '#'"
          class="cta-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          See Product
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getImagePath(imagePath) {
      try {
        return require(`@/assets/images/${imagePath}`);
      } catch (error) {
        console.error("Error loading image:", imagePath, error);
        return require("@/assets/images/vacuum.png"); // Fallback image
      }
    },
    formatKey(key) {
      // Convert keys like "fit_and_size" to "Fit and Size"
      return key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    },
  },
};
</script>

<style scoped>
.mattress-card {
  font-family: Arial, sans-serif;
  color: #333;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
  max-width: 100%;
  margin: 1rem auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #122c5c;
  color: #fff;
  padding: 1rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.best-value-badge {
  font-weight: bold;
}

.content-section {
  display: flex;
  gap: 2rem;
  padding: 1rem;
}

.image-gallery {
  flex: 1;
}

.main-image {
  width: 100%;
  border-radius: 4px;
}

.product-info {
  flex: 1;
}

.highlights {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.highlights li {
  margin-bottom: 0.5rem;
}

.cta-button {
  display: inline-block;
  background: #28a745;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
}

.cta-button:hover {
  background: #218838;
}
</style>
