import { createRouter, createWebHistory } from "vue-router";
import ProductPage from "@/pages/product-page.vue";
import AboutPage from "@/pages/about-page.vue";
import HomePage from "@/pages/home-page.vue";
import TemuPage from "@/pages/temu-page.vue";
import CMSPage from "@/pages/cms-page.vue";
import ContentPage from "@/pages/article-manager-page.vue";
import NotFoundPage from "@/pages/not-found-page.vue";

const routes = [
  { path: "/", component: HomePage },
  { path: "/about", component: AboutPage },
  { path: "/product/:productName", component: ProductPage },
  {
    path: "/temu/:articleID", // Dynamic parameter in the route
    component: TemuPage,
  },
  { path: "/product-cms", component: CMSPage },
  { path: "/content-cms", component: ContentPage },
  {
    path: "/:pathMatch(.*)*", // Catch-all route for 404
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
